import {
    PublicationsConfigEntry,
    VersionComparisonFunction,
    VersionFilterTag,
    VersionName,
} from "./types";

export const PALIGO_DOCUMENT_NODE_TYPE: string = "PaligoDocumentNode";
export const PALIGO_FORK_NODE_TYPE: string = "PaligoForkNode";
export const PALIGO_PUBLICATION_NODE_TYPE: string = "PaligoPublicationNode";
export const DOCUMENT_NODE_TYPE: string = "DocumentNode";
export const DOCUMENT_UUID_ELEMENT_NODE_TYPE: string =
    "DocumentUuidElementNode";
export const PRODUCT_NODE_TYPE: string = "ProductNode";
export const PRODUCT_VERSION_NODE_TYPE: string = "ProductVersionNode";
export const PRODUCT_VERSION_LANDING_PAGE_NODE_TYPE: string =
    "ProductVersionLandingPageNode";
export const VARIABLE_VARIANT_NODE_TYPE: string = "VariableVariantNode";
export const DOCUMENT_XI_INCLUDE_TAG_NODE_TYPE: string =
    "DocumentXiIncludeTagNode";
export const ORPHAN_DOCUMENT_XI_INCLUDE_TAG_NODE_TYPE: string =
    "OrphanDocumentXiIncludeTagNode";
export const GLOSSARY_TERM_NODE_TYPE: string = "GlossaryTermNode";
export const IMAGE_TAG_NODE_TYPE: string = "ImageTagNode";
export const IMAGE_NODE_TYPE: string = "ImageNode";

export const PALIGO_GLOSSARY_DOCUMENT_ID: number = 743666;

export const PRINT_PARSING_ERRORS: boolean =
    process.env.NODE_ENV === "development" ||
    process.env.PRINT_PARSING_ERRORS === "true";

// the maxmimum depth documents can have, any documents with depth more than
// this number will all be collated into the nearest valid parent document
// up the document tree
export const MAXIMUM_DOCUMENT_DEPTH: number = 4;

// return true if the passed version is allowed given the version requirement
// label (which is the key of the object)
// API doesn't export labels so we have to define this manually
// update VersionFilterTag type when new version filters are added
export const VERSION_FILTER_FUNCTIONS: Record<
    VersionFilterTag,
    VersionComparisonFunction
> = {
    "9.0": (currentVersion: VersionName) => parseFloat(currentVersion) >= 9.0,
    "9.0 Only": (currentVersion: VersionName) =>
        parseFloat(currentVersion) === 9.0,
    "8.9": (currentVersion: VersionName) => parseFloat(currentVersion) >= 8.9,
    "8.9 Only": (currentVersion: VersionName) =>
        parseFloat(currentVersion) === 8.9,
    "8.7": (currentVersion: VersionName) => parseFloat(currentVersion) >= 8.7,
    "8.7 Only": (currentVersion: VersionName) =>
        parseFloat(currentVersion) === 8.7,
    "8.5": (currentVersion: VersionName) => parseFloat(currentVersion) >= 8.5,
    "8.5 Only": (currentVersion: VersionName) =>
        parseFloat(currentVersion) === 8.5,
    "8.1": (currentVersion: VersionName) => parseFloat(currentVersion) >= 8.1,
    "8.1 Only": (currentVersion: VersionName) =>
        parseFloat(currentVersion) === 8.1,
    "8.0 GA": (currentVersion: VersionName) =>
        parseFloat(currentVersion) >= 8.0,
    "8.0 Only": (currentVersion: VersionName) =>
        parseFloat(currentVersion) === 8.0,
    "8.0": (currentVersion: VersionName) => parseFloat(currentVersion) >= 8.0,
    "7.8 Only": (currentVersion: VersionName) =>
        parseFloat(currentVersion) === 7.8,
    "7.8": (currentVersion: VersionName) => parseFloat(currentVersion) >= 7.8,
    "7.6 Only": (currentVersion: VersionName) =>
        parseFloat(currentVersion) === 7.6,
    "7.6": (currentVersion: VersionName) => parseFloat(currentVersion) >= 7.6,
    "7.5 to 7.6": (currentVersion: VersionName) =>
        parseFloat(currentVersion) >= 7.5 && parseFloat(currentVersion) <= 7.6,
    "7.5 Only": (currentVersion: VersionName) =>
        parseFloat(currentVersion) === 7.5,
    "7.5": (currentVersion: VersionName) => parseFloat(currentVersion) >= 7.5,
    "7.3 Only": (currentVersion: VersionName) =>
        parseFloat(currentVersion) === 7.3,
    "7.3": (currentVersion: VersionName) => parseFloat(currentVersion) >= 7.3,
    "Removed starting in 7.5": (currentVersion: VersionName) =>
        parseFloat(currentVersion) < 7.5,
    "Removed starting in 7.6": (currentVersion: VersionName) =>
        parseFloat(currentVersion) < 7.6,
    "Removed starting in 7.8": (currentVersion: VersionName) =>
        parseFloat(currentVersion) < 7.8,
    "Removed starting in 8.0": (currentVersion: VersionName) =>
        parseFloat(currentVersion) < 8.0,
    "Removed starting in 8.1": (currentVersion: VersionName) =>
        parseFloat(currentVersion) < 8.1,
    "Removed starting in 8.5": (currentVersion: VersionName) =>
        parseFloat(currentVersion) < 8.5,
    "Removed starting in 8.7": (currentVersion: VersionName) =>
        parseFloat(currentVersion) < 8.7,
    "Removed starting in 8.9": (currentVersion: VersionName) =>
        parseFloat(currentVersion) < 8.9,
    "Removed starting in 9.0": (currentVersion: VersionName) =>
        parseFloat(currentVersion) < 9.0,
    Hidden: () => false,
};

export const IS_STAGING_BUILD =
    Boolean(process.env.GATSBY_STAGING_SELF_MANAGED_PUBLICATION_ID) ||
    Boolean(process.env.GATSBY_STAGING_CLOUD_PUBLICATION_ID);

const CURRENT_VERSION: VersionName = "8.9";

// this is the source of truth for products and versions across the whole site
// any updates here will result in products and versions being added/removed.
// the order of products in this array will determine the order they appear in
// the version selector and other areas around the site
export const getAllUsedPublications = (): Array<PublicationsConfigEntry> => {
    // base publications for each product

    const cloudConfig: PublicationsConfigEntry = {
        id: 650724, // paligo ID
        active: !process.env.GATSBY_STAGING_CLOUD_PUBLICATION_ID,
        name: "SingleStore Helios",
        filterName: "SingleStore Managed Service", // the name used to filter elements in Paligo
        shortName: "Helios", // the name shown in the product/version selector and breadcrumbs
        slug: "cloud", // the URL slug for this product
        landingPageId: 809518, // paligo ID
        productVariableVariantId: 12, // for the "Products" variable set, which variant does this publication use
        defaultPublication: true, // default publications will have their landing page at docs.singlestore.com
        currentVersion: CURRENT_VERSION, // this should be the same as self-managed current version
        pastVersions: [],
        archivedVersions: [],
    };

    const selfManagedConfig: PublicationsConfigEntry = {
        id: 650723, // paligo ID
        active: !process.env.GATSBY_STAGING_SELF_MANAGED_PUBLICATION_ID,
        name: "SingleStore Self-Managed",
        filterName: "SingleStore DB", // the name used to filter elements in Paligo
        shortName: "Self-Managed", // the name shown in the product/version selector and breadcrumbs
        slug: "db", // the URL slug for this product
        landingPageId: 821270, // paligo ID
        hideChildrenInNavbar: [684263], // a list of Paligo IDs of documents who's children should not be displayed in the navbar
        productVariableVariantId: 11, // for the "Products" variable set, which variant does this publication use
        defaultPublication: false,
        currentVersion: CURRENT_VERSION,
        pastVersions: ["7.3", "7.5", "7.6", "7.8", "8.0", "8.1", "8.5", "8.7"],
        archivedVersions: [
            "5.0",
            "5.1",
            "5.5",
            "5.7",
            "5.8",
            "6.0",
            "6.5",
            "6.7",
            "6.8",
            "7.0",
            "7.1",
        ],
    };

    const publicationsConfig: Array<PublicationsConfigEntry> = [
        cloudConfig,
        selfManagedConfig,
    ];

    // add staging publications if configured

    if (process.env.GATSBY_STAGING_CLOUD_PUBLICATION_ID) {
        publicationsConfig.push({
            ...cloudConfig,
            id: Number(process.env.GATSBY_STAGING_CLOUD_PUBLICATION_ID), // paligo ID
            active: true,
            originalPublicationId: cloudConfig.id,
            name: "SingleStoreDB Cloud (staging)",
            shortName: "Cloud (staging)",
        });
    }

    if (process.env.GATSBY_STAGING_SELF_MANAGED_PUBLICATION_ID) {
        publicationsConfig.push({
            ...selfManagedConfig,
            id: Number(process.env.GATSBY_STAGING_SELF_MANAGED_PUBLICATION_ID), // paligo ID
            active: true,
            originalPublicationId: selfManagedConfig.id,
            name: "SingleStoreDB Self-Managed (staging)",
            shortName: "Self-Managed (staging)",
        });
    }

    return publicationsConfig;
};

export const getAllActivePublications = (): Array<PublicationsConfigEntry> => {
    return getAllUsedPublications().filter(({ active }) => active);
};
